<template>
  <div id="VuelosEscogidos" class="vuelosEscogidos">
    <div class="vuelosEscogidos__overlay d-none d-md-block">
      <div class="vuelosEscogidos__overlay__bgImg" :style="imgFondo"></div>
    </div>
    <!-- :class="{'container' : containerOContainerfluid}" -->
    <v-container class="vuelosEscogidos__info mx-auto">
      <v-row no-gutters class="mx-auto">
        <v-col cols="1" class="d-none d-md-block">
          <FlechaAtras :blanco="true" />
        </v-col>
        <!-- Mapa e info general -->
        <v-col cols="12" md="6" lg="7" xl="8" class="pr-md-3">
          <div class="vuelosEscogidos__titulo d-none d-md-block pb-6">
            <h1>
              {{
                vuelosElegidos.info.tipoDeViaje === "Vuelos"
                  ? "Vuelo"
                  : "Experiencia a"
              }}
            </h1>
            <h1>{{ tituloRuta }}</h1>
          </div>
          <Map class="vuelosEscogidos__mapSection mb-4" />
          <v-container
            class="vuelosEscogidos__infoGeneral d-none d-md-block shaped px-11"
          >
            <h3 class="pb-7">Detalles vuelo</h3>

            <div class="detalles" v-if="vuelosElegidos.info.tipoDeViaje === 'Experiencias'">
              <h2 class="pb-4">
                {{ vuelosElegidos.ida.data.destino }}
                <span class="puntoOrigen" v-if="vuelosElegidos.ida.data.origen">
                  desde {{ vuelosElegidos.ida.data.origen }}
                </span>
              </h2>
              <p class="pb-3">
                {{ vuelosElegidos.ida.data.descripcion }}
              </p>

              <div class="pb-3" v-if="vuelosElegidos.ida.data.direccionOrigen">
                <h3>Salida y llegada</h3>
                <p>{{ vuelosElegidos.ida.data.direccionOrigen }}</p>
              </div>

              <div class="pb-3">
                <h3>Fecha</h3>
                <p>
                  {{
                    vuelosElegidos.ida.data.fecha.split("-").reverse().join("-")
                  }}
                </p>
                <p>
                  {{ vuelosElegidos.ida.data.horaDespegue }}
                  <span v-if="vuelosElegidos.ida.data.horaLlegada">
                    a {{ vuelosElegidos.ida.data.horaLlegada }}</span
                  >
                  hrs
                </p>
              </div>
              <h3>Detalles vuelo</h3>
              <ul class="listaDetalles" v-if="vuelosElegidos.ida.data.detalles">
                <li
                  v-for="(detalle, i) of vuelosElegidos.ida.data.detalles"
                  :key="i"
                  class="my-3"
                >
                  {{ detalle }}
                </li>
              </ul>
            </div>

            <v-row no-gutters>
              <v-col
                v-for="(detalle, i) of detallesVuelo"
                :key="i"
                cols="6"
                class="d-flex align-center pb-6"
              >
                <img
                  :src="detalle.icono"
                  alt="icono equipaje"
                  class="pr-5"
                  height="25"
                />
                <h4>{{ detalle.texto }}</h4>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <!-- 'Ticket' con detalles -->
        <v-col cols="12" md="5" lg="4" xl="3" class="pl-md-3">
          <v-container
            class="vuelosEscogidos__detalles d-flex flex-column justify-center"
          >
            <div
              class="detalles d-md-none"
              v-if="vuelosElegidos.info.tipoDeViaje === 'Experiencias'"
            >
              <h2 class="pb-4">
                {{ vuelosElegidos.ida.data.destino }}
                <span class="puntoOrigen" v-if="vuelosElegidos.ida.data.origen">
                  desde {{ vuelosElegidos.ida.data.origen }}
                </span>
              </h2>
              <div v-if="verDetalles">
                <p class="pb-3">
                  {{ vuelosElegidos.ida.data.descripcion }}
                </p>

                <div
                  class="pb-3"
                  v-if="vuelosElegidos.ida.data.direccionOrigen"
                >
                  <h3>Salida y llegada</h3>
                  <p>{{ vuelosElegidos.ida.data.direccionOrigen }}</p>
                </div>

                <div class="pb-3">
                  <h3>Fecha</h3>
                  <p>
                    {{
                      vuelosElegidos.ida.data.fecha
                        .split("-")
                        .reverse()
                        .join("-")
                    }}
                  </p>
                  <p>
                    {{ vuelosElegidos.ida.data.horaDespegue }}
                    <span v-if="vuelosElegidos.ida.data.horaLlegada">
                      a {{ vuelosElegidos.ida.data.horaLlegada }}</span
                    >
                    hrs
                  </p>
                </div>
                <h3>Detalles vuelo</h3>
                <ul
                  class="listaDetalles"
                  v-if="vuelosElegidos.ida.data.detalles"
                >
                  <li
                    v-for="(detalle, i) of vuelosElegidos.ida.data.detalles"
                    :key="i"
                    class="my-3"
                  >
                    {{ detalle }}
                  </li>
                </ul>
              </div>
              <div class="text-center my-4">
                <v-btn @click="verDetalles = !verDetalles" :color="colors.mainPurple" width="100%" rounded dark  small>
                  {{ verDetalles ? "Ocultar detalles" : "Ver detalles de experiencia" }}
                </v-btn>
              </div>
            </div>
            <CardResumenVuelo />
            
            <div @click="continuar">
              <Boton
                texto="Reservar"
                width="100%"
                class="mt-4"
                height="45px"
              />
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FlechaAtras from "@/components/FlechaAtras";
import CardResumenVuelo from "@/components/Cards/CardResumenVuelo";
import Map from "@/components/Map";
import Boton from "@/components/Botones/Boton";
import { mapState } from "vuex";
export default {
  name: "VuelosEscogidos",
  components: {
    FlechaAtras,
    CardResumenVuelo,
    Map,
    Boton,
  },
  data() {
    return {
      aceptarTerminos: false,
      verDetalles: false,
    };
  },
  computed: {
    ...mapState(["icons", "colors"]),
    ...mapState("Ventas", ["vuelosElegidos"]),
    tituloRuta() {
      return this.vuelosElegidos.info.tipoDeViaje === "Vuelos"
        ? `${this.vuelosElegidos.ida.data.origen} - ${this.vuelosElegidos.ida.data.destino}`
        : `${this.vuelosElegidos.ida.data.destino}`;
    },
    imgFondo() {
      let src =
        typeof this.vuelosElegidos.ida.data.img === "string"
          ? this.vuelosElegidos.ida.data.img
          : this.vuelosElegidos.ida.data.img[0];

      return { backgroundImage: `url(${src})` };
    },
    containerOContainerfluid() {
      const breakpoint = 960;
      const viewport = this.$vuetify.breakpoint.width;
      return viewport > breakpoint;
    },
    detallesVuelo() {
      return [
        {
          icono: this.icons.purple.luggage,
          texto: "Equipaje de mano y de cabina",
        },
        {
          icono: this.icons.purple.seat,
          texto: this.puestosDisponibles + " asientos disponibles",
        },
      ];
    },
    puestosDisponibles() {
      const capacidadIda = this.vuelosElegidos.ida.data.capacidad;
      const ventasIda = this.vuelosElegidos.ida.data.ventas;
      const asientosDisponiblesIda = capacidadIda - ventasIda;

      let pasajerosPermitidos;
      if (!this.vuelosElegidos.vuelta) {
        pasajerosPermitidos = asientosDisponiblesIda;
      } else {
        const capacidadVuelta = this.vuelosElegidos.vuelta.data.capacidad;
        const ventasVuelta = this.vuelosElegidos.vuelta.data.ventas;
        const asientosDisponiblesVuelta = capacidadVuelta - ventasVuelta;
        const menorDisponibilidad = Math.min(
          asientosDisponiblesIda,
          asientosDisponiblesVuelta
        );
        pasajerosPermitidos = menorDisponibilidad;
      }

      return pasajerosPermitidos;
    },
  },
  methods: {
    continuar() {
      this.$router.push({ name: "Pago" })
    },
  },
  mounted() {},
  title() {
    return `Vuelos seleccionados - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
.detalles {
  ul {
    list-style: initial;
  }
}
#VuelosEscogidos,
.vuelosEscogidos {
  padding-top: 100px;
  background-color: $gray-background;
  min-height: 100vh;

  &__info {
    position: absolute;
    top: 112px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__detalles {
    @include roundedAndElevated;
  }
  h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 45px;
    color: $main-white;
  }
  &__mapSection {
    height: 280px;
    width: 100%;
  }
  &__infoGeneral {
    h4 {
      font-size: 16px;
    }
  }
}

.shaped {
  background: $main-white;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}

@media (min-width: 960px) {
  #VuelosEscogidos,
  .vuelosEscogidos {
    &__overlay {
      height: 450px;
      background-color: $main-black;
      animation-name: traslateFromTop;
      animation-duration: 0.8s;
      &__bgImg {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.4;
        filter: blur(4px);
      }
    }
    &__mapSection {
      height: 480px;
    }
    &__detalles {
      background: $main-white;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.1);
      border-radius: 14px;
    }
  }
}
</style>